
import { defineComponent, PropType } from 'vue';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { Question } from '@/lib/interfaces';

export default defineComponent({
  name: 'QuestionRadio',
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true,
    },
    selectedValue: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState(['responses']),
    ...mapGetters(['getResponseByQuestionId']),
  },
  methods: {
    ...mapMutations([
      'UPDATE_RESPONSE',
    ]),
  },
});
