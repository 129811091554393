
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { mapState, mapGetters, mapMutations } from 'vuex';
import QuestionRadio from '@/components/QuestionRadio.vue';
import {
  Outcome,
  OutcomeCriteria,
  QuestionResponse,
  OutcomeSuitability,
  multipleOutcomes,
  orderMultipleOutcomesByScore,
} from '@/lib/totaliser';
import store from '@/store';
import Criteria from '@/data/criteria.json';
import ShapeMask from './atoms/ShapeMask.vue';

export default defineComponent({
  name: 'Form',
  components: {
    QuestionRadio,
    ShapeMask,
  },
  setup() {
    const router = useRouter();
    const questions = store.getters.getQuestions;
    const maxQuestions = questions.length - 1;
    const questionCounter = ref(0);
    const currentQuestion = computed(() => questions[questionCounter.value]);
    const nextBtnDisabled = computed(() => {
      const userResponses: QuestionResponse[] = store.state.responses;
      if (userResponses[questionCounter.value].responseIndex !== null) {
        return false;
      }
      return true;
    });
    const currentSelectedValue = computed(() => {
      const userResponses: QuestionResponse[] = store.state.responses;
      if (userResponses[questionCounter.value] !== undefined) {
        if (userResponses[questionCounter.value].responseIndex !== null) {
          return userResponses[questionCounter.value].responseIndex;
        }
      }
      return 'No value';
    });
    const submitAnswer = () => {
      questionCounter.value += 1;
    };
    const previousQuestion = () => {
      if (questionCounter.value > 0) {
        questionCounter.value -= 1;
      }
    };
    const finishQuestionnaire = () => {
      const criteria = Criteria;
      const outcomes: Outcome[] = [];
      Object.keys(criteria.criteria).forEach((criteriaKey: string) => {
        const criteriaObject: { id: string, criteria: { [key:string]: OutcomeCriteria } } = {
          id: criteria.criteria[Number(criteriaKey)].title,
          criteria: {},
        };

        Object.keys(criteria.criteria[Number(criteriaKey)].criteria).forEach((questionKey: string) => {
          const questionCriteria = criteria.criteria[Number(criteriaKey)].criteria[Number(questionKey)];
          criteriaObject.criteria[questionCriteria.id] = questionCriteria;
        });
        outcomes.push(criteriaObject);
      });
      const userResponses: QuestionResponse[] = store.state.responses;
      const result: OutcomeSuitability[] = multipleOutcomes(outcomes, userResponses);
      store.commit('UPDATE_RESULTS', orderMultipleOutcomesByScore(result));
      router.push({ name: 'Feedback' });
    };
    return {
      questions,
      maxQuestions,
      questionCounter,
      currentQuestion,
      currentSelectedValue,
      submitAnswer,
      previousQuestion,
      finishQuestionnaire,
      nextBtnDisabled,
    };
  },
  computed: {
    ...mapState(['responses']),
    ...mapGetters(['getQuestions', 'getResults']),
  },
  methods: {
    ...mapMutations([
      'UPDATE_RESULTS',
    ]),
  },
});
